


































































import { Company } from '@app/models';
import fb from 'firebase/app';
import { Vue, Component } from 'vue-property-decorator';

import AddCompanyDialog from '../../components/3-dialogs/dialog-add-company.vue';
import CompanyNavBar from '../../components/1-navigation/company-nav-bar.vue';

@Component({
  components: { AddCompanyDialog, CompanyNavBar },
})
export default class AdminCompanyManagement extends Vue {
  public currentPage = 1;
  public perPage = 10;

  public get companies() {
    return this.$company.list;
  }

  public get fields() {
    return [
      {
        key: 'name',
        label: this.$t('company-name'),
        sortable: true,
      },
      {
        key: 'email',
        label: this.$t('email'),
        sortable: true,
      },
      {
        key: 'created',
        label: this.$t('creationDate'),
        sortable: true,
        formatter: (value: fb.firestore.Timestamp) => this.date(value),
      },
      {
        key: 'users',
        label: this.$t('users'),
        sortable: true,
        formatter: (value: any) => value.length,
      },
      {
        key: 'phone',
        label: this.$t('phone'),
        sortable: true,
      },
      {
        key: 'actions',
        label: '',
        thClass: 'th-actions',
        tdClass: 'td-actions',
      },
    ];
  }

  public async deleteCompany(company: Company) {
    await this.$firebase.doc(`company/${company.id}`).delete();
  }

  private date(timestamp: fb.firestore.Timestamp) {
    return timestamp.toDate().toISOString().slice(0, 10);
  }
}
