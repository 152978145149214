




































































import { getColorShade } from '@bcase/core';
import { File } from '@bcase/module-editor';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { Company } from '../../../../models/src';

@Component
export default class AddCompanyDialog extends Vue {
  @Prop({ type: Object, default: () => {} })
  public company!: Company;

  public get color() {
    const { color } = this.company;
    const [c1, c2] = (color ? color : this.defaultColor).split(' ');
    return `${c1} ${c2 || '#ffffff'}`;
  }

  public get defaultColor() {
    const htmlRoot = document.querySelector(':root') as HTMLElement;
    return getColorShade(htmlRoot, 'primary');
  }

  mounted() {
    this.watchLogo(this.company.logo);
  }

  @Watch('company.logo')
  public watchLogo(logo: string[]) {
    const upload = this.$refs['logo'] as HTMLBceUploadElement;
    upload.value = logo;
    upload.metadata = {
      ref: `company/${this.company.id}/logo.png`,
      group: `company-${this.company.id}`,
    };
  }

  public async discard() {
    const dialog = this.$el as HTMLBceDialogElement;
    const logos = this.company.created ? [] : this.company.logo;
    for (const logo of logos) {
      const upload = this.$refs['logo'] as HTMLBceUploadElement;
      upload.delete(logo);
    }

    dialog.hide();
    this.$emit('discard');
  }

  public submit() {
    this.$emit('submit');
  }
}
